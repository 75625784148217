<template>
  <section id="sponsor">
    <b-row>
      <b-col>
        <b-card>
          <b-card-header class="head">
            <b-card-title>{{ $t('contents.categories') }}</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-overlay
              variant="transparent"
              :show="showCategories"
            >
              <b-row class="m-2">
                <b-col md="6">
                  <b-row>
                    <b-col md="5">
                      <h6 class="text-primary font-weight-bold mb-2">
                        {{ $t("code.allC") }}
                        <feather-icon
                          icon="PlusCircleIcon"
                          size="20"
                          class="text-success cursor-pointer"
                          @click="AddCat()"
                        />
                      </h6>
                    </b-col>
                    <b-col md="4">
                      <b-form-input
                        v-model="searchCategories"
                        :placeholder="$t('subscriptions.minCharact')"
                        minlength="3"
                        @keyup="searchCategorieByName"
                      />
                    </b-col>
                    <b-col>
                      <b-button
                        variant="primary"
                        @click="searchCategorieByName(null, true)"
                      >
                        <feather-icon
                          icon="SearchIcon"
                          size="16"
                        />
                      </b-button>
                    </b-col>
                  </b-row>

                  <draggable
                    ref="catlist"
                    :handle="resCategories.length == 0 ? '.handle' : false"
                    :list="resCategories"
                    tag="ul"
                    group="catlist"
                    class="scroll-list list-group list-group-flush cursor-move"
                  >
                    <b-list-group-item
                      v-for="c in resCategories"
                      :key="c.node.id"
                      tag="li"
                      @dragend="updateResCategories"
                    >
                      <div class="d-flex">
                        <div class="ml-25">
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ c.node.name }}
                          </b-card-text>
                        </div>
                      </div>
                    </b-list-group-item>
                    <div
                      v-if="resCategories.length == 0"
                      class="mt-2"
                    >
                      {{ emptySearchCat }}
                    </div>
                  </draggable>
                </b-col>
                <b-col
                  md="6"
                  class="mt-sm-2 mt-md-0"
                >
                  <h6 class="text-primary font-weight-bold mb-2">
                    {{ $t('subscriptions.selectedCategory') }}
                    <feather-icon
                      icon="XCircleIcon"
                      size="20"
                      class="text-danger cursor-pointer"
                      @click="dropCat($event)"
                    />
                  </h6>

                  <draggable
                    ref="catsel"
                    :list="categorySelected"
                    tag="ul"
                    group="catlist"
                    class="scroll-list list-group list-group-flush cursor-move"
                  >
                    <b-list-group-item
                      v-for="(catSus, index) in categorySelected"
                      :key="index"
                      tag="li"
                      @dragend="updateCategorySelected"
                    >
                      <div class="d-flex">
                        <div class="ml-25">
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ catSus.node.name }}
                          </b-card-text>
                        </div>
                      </div>
                    </b-list-group-item>
                  </draggable>
                </b-col>
              </b-row>
            </b-overlay>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  BCard,
  BListGroupItem,
  BCardText,
  BCol,
  BRow,
  BCardTitle,
  BCardBody,
  BButton,
  BOverlay,
  BCardHeader,
  BFormInput,
  VBTooltip,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive' // Notification
import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'

import axios from '@axios'
import { messageError } from '@/store/functions'

export default {
  components: {

    BOverlay,
    BCard,
    BCardTitle,
    BCardBody,
    BListGroupItem,
    BCardText,
    BCol,
    BCardHeader,
    BRow,
    BButton,

    BFormInput,
    draggable,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  data() {
    return {
      userData: getUserData(),
      categorySelected: [],
      categories: [],
      resCategories: [],
      searchCategories: '',
      emptySearchCat: null,
      allCats: [],
      userId: this.$route.params.id,
      showCategories: false,
    }
  },
  watch: {
    resCategories() {
      this.$emit('categoriesSelected', this.categorySelected)
    },
  },
  mounted() {
    this.allCategories()
  },
  methods: {
    dropCat(event) {
      if (this.categorySelected.length !== 0) {
        this.allCats = [...this.categorySelected, ...this.allCats]

        this.categorySelected = []

        this.searchCategorieByName(event, true)
      }
    },
    AddCat() {
      if (this.resCategories.length !== 0) {
        this.categorySelected = [...this.categorySelected, ...this.resCategories]

        this.resCategories.forEach(element => {
          this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
        })

        this.resCategories = []
      }
    },
    updateResCategories() {
      this.categorySelected.forEach(element => {
        this.allCats = this.allCats.filter(e => element.node.id !== e.node.id)
      })
    },
    updateCategorySelected() {
      const difference = this.resCategories.filter(
        x => !this.allCats.includes(x),
      )

      this.allCats = [...difference, ...this.allCats]
    },
    searchCategorieByName(event = null, pass = false) {
      let keycode = 0
      if (event) { keycode = event.keyCode || event.which }

      if (keycode === 13 || pass) {
        if (this.searchCategories.trim().length >= 3) {
          this.getCategories()
        } else {
          this.resCategories = []
          this.emptySearchCat = this.$t('introduceNameCat')
        }
      }
    },
    allCategories() {
      const query = `{
                    allProfiles(id:"${this.userId}"){
                        totalCount
                        edgeCount
                        edges {
                            node {
                            sponsorCategory {
                                edges {
                                    node {
                                    id
                                    name
                                    }
                                }
                            }                    
                        }
                    }
                }
            }`
      axios
        .post('', {
          query,
        }).then(result => {
          messageError(result, this)

          const res = result.data.data.allProfiles.edges[0].node
          this.categorySelected = res.sponsorCategory.edges

          if (this.categorySelected.length === 0) this.categorySelected = []
        }).catch(() => {

        })
    },
    getCategories() {
      this.showCategories = true
      axios
        .post('', {
          query: `
           query{
            categoriesUnlimited(client:"${this.userData.profile.client.id}",name:"${this.searchCategories}"
            
            ){
                edges {
                    node {
                    id
                    name                    
                    }
                }
            }                
        }   
        `,
        })
        .then(response => {
          messageError(response, this)

          const arr2 = response.data.data.categoriesUnlimited.edges
          const array = []
          for (let i = 0; i < arr2.length; i += 1) {
            let bool = false
            for (let j = 0; j < this.categorySelected.length; j += 1) {
              if (this.categorySelected[j].node.id === arr2[i].node.id) {
                bool = true
              }
            }

            if (!bool) {
              array.push(arr2[i])
            }
          }

          this.categories = arr2.slice()

          // eslint-disable-next-line no-multi-assign
          this.resCategories = this.allCats = array.slice()

          this.emptySearchCat = this.$t('resultNotFound')
          this.showCategories = false
        })
    },
  },

}
</script>
<style lang="scss" scoped>

#sponsor .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 200px;
  // border: 1px solid;
  margin: 2px;
}

#sponsor .head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
