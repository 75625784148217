<template>
  <section id="final-users">
    <b-row>
      <b-col>
        <h1>{{ $t("administrators.title") }}</h1>
        <h5 class="text-primary">
          {{ $t("administrators.edit") }}
        </h5>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-end m-1">
          <router-link to="/users/administrators">
            <b-button variant="danger">
              {{ $t("dataGeneric.goBack") }}
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col />
    </b-row>

    <b-card class="mt-5">
      <validation-observer ref="simpleRules">
        <b-form @input="changeForm()">
          <b-tabs v-if="introspectionData.username && userData">
            <b-tab active>
              <template #title>
                <feather-icon icon="UserIcon" />
                <span> {{ $t("User") }} </span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.usIn") }}
                </b-card-title>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      :label="$t('administrators.user')"
                      label-for="mc-user-name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="User Name"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-user-name"
                          v-model="userData.username"
                          disabled
                          autocomplete="new-password"
                          :placeholder="$t('administrators.user')"
                          maxlength="75"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.firstName.description
                      "
                      :label="$t('administrators.name')"
                      label-for="mc-first-name"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="userData.firstName"
                        :placeholder="$t('administrators.name')"
                        autocomplete="new-password"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastName.description
                      "
                      :label="$t('administrators.lname')"
                      label-for="mc-last-name"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="userData.lastName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.lname')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-card-title class="mt-1">
                  {{ $t("Permisos") }}
                </b-card-title>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      :label="$t('PermisosUsuario')"
                      label-for="permissionGroups"
                    >
                      <v-select
                        id="permissionGroups"
                        v-model="selectedPermissionGroups"
                        :options="permissionGroups"
                        multiple
                        label="name"
                        aria-autocomplete="new-password"
                        autocomplete="new-password"
                        :selectable="(option) => forbid ? !option.name.includes(constants.administrador) : true"
                        @input="changeForm()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-if="!isEmpty(selectedPermissionGroups) ? includesPermission(constants.vendor) : false"
                      :label="$t('shopUser')"
                      label-for="profileVendor"
                    >
                      <v-select
                        id="profileVendor"
                        v-model="selectedProfileVendor"
                        :options="vendorGroups"
                        label="vendorName"
                        :placeholder="$t('shopUser')"
                        aria-autocomplete="new-password"
                        autocomplete="new-password"
                        @input="changeForm()"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="KeyIcon" />
                <span> {{ $t('administrators.pass') }} </span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t('changPass') }}
                </b-card-title>
                <b-row>
                  <b-col md="6">
                    <!-- Password -->
                    <validation-provider
                      v-slot="validationContext"
                      name="password"
                      vid="Password"
                      rules="min:8|password"
                    >
                      <b-form-group
                        :label="$t('administrators.pass')"
                        label-for="password"
                      >
                        <b-input-group>
                          <b-form-input
                            id="password"
                            ref="password"
                            v-model="userData.password"
                            autocomplete="new-password"
                            :type="passwordFieldType"
                            :placeholder="$t('administrators.pass')"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{
                          checkPassword(validationContext.failedRules)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <!-- c-password -->
                    <validation-provider
                      v-slot="validationContext"
                      name="c-password"
                      vid="c-password"
                      rules="confirmed:Password"
                    >
                      <b-form-group
                        :label="$t('administrators.repass')"
                        label-for="c-password"
                      >
                        <b-input-group>
                          <b-form-input
                            id="c-password"
                            v-model="userData.cpassword"
                            autocomplete="new-password"
                            :type="passwordFieldType"
                            :placeholder="$t('administrators.repass')"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{
                          ckeckConfirmPassword(validationContext.failedRules)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="AtSignIcon" />
                <span>
                  {{ $t("administrators.contact") }}
                </span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.contact2") }}
                </b-card-title>
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.email.description
                      "
                      label="E-mail"
                      label-for="mc-user-email"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="User Email"
                        rules="email|required"
                      >
                        <b-form-input
                          id="mc-user-email"
                          v-model="userData.email"
                          autocomplete="new-password"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t('administrators.email')"
                          maxlength="254"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SettingsIcon" />
                <span> {{ $t("administrators.info") }} </span>
              </template>
              <b-card-body>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.dateJoined.description
                      "
                      :label="$t('administrators.date')"
                      label-for="mc-dateJoined-name"
                    >
                      <b-form-datepicker
                        disabled
                        :value="userData.dateJoined"
                        :readonly="true"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastLogin.description
                      "
                      :label="$t('administrators.last')"
                      label-for="mc-lastLogin-name"
                    >
                      <b-form-datepicker
                        disabled
                        :value="userData.lastLogin"
                        :readonly="true"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab v-if="!isEmpty(selectedPermissionGroups) ? includesPermission(constants.sponsor) : false">
              <template #title>
                <feather-icon icon="TagIcon" />
                <span>{{ $t('Microcanales') }}</span>
              </template>
              <admin-sponsor-categories @categoriesSelected="categoriesSelected" />
            </b-tab>
          </b-tabs>
        </b-form>
      </validation-observer>
      <b-button
        :disabled="disabled"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="clck()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>
    </b-card>
  </section>
</template>

<script>
import * as constants from '@core/utils/constants'

import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import {
  BCard,
  BTabs,
  BTab,
  BCol,
  BRow,
  BCardTitle,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BForm,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, length } from '@validations'
import Ripple from 'vue-ripple-directive' // Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import selectOptions from '@/libs/utils/v-select-from-reflection'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'
import { isEmpty } from '@/store/functions'
import i18n from '@/libs/i18n'
import AdminSponsorCategories from './AdminSponsorCategories.vue'
import userStoreModule from './administratorsStoreModule'

let forbid = false
let isShop = false

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BCardBody,
    BTabs,
    BTab,
    BCol,
    BRow,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    AdminSponsorCategories,
    BForm,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [togglePasswordVisibility],
  setup() {
    const simpleRules = ref(null)
    const toast = useToast()
    const userData = ref(null)
    const permissionGroups = ref(null)
    const selectedPermissionGroups = ref(null)
    const vendorGroups = ref(null)
    const selectedProfileVendor = ref(null)
    const introspectionData = ref({})

    store
      .dispatch('admin-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.data.allProfiles.edges[0].node
        const arrayExclude = getUserData().isSuperuser ? [] : constants.exludeList
        const allGroups = response.data.data.allGroups.edges
          .filter(group => {
            const { name } = group.node
            return (
              !arrayExclude.includes(name) // Evita los nombres en arrayExclude
              && ((name === constants.vendor && isShop) // Condición para vendor
                || (name === constants.administrador && !forbid) // Condición para administrador
                || (name !== constants.vendor && name !== constants.administrador)) // Cualquier otro nombre
            )
          })
          .map(element => ({
            node: {
              id: element.node.id,
              name: element.node.name,
            },
          }))

        const vendors = response.data.data.allVendors.edges
        const tempArray = []
        const vendArray = []
        const tempObject = {}
        allGroups.forEach(element => {
          // eslint-disable-next-line no-param-reassign
          element.node.name = i18n.t(element.node.name)
          tempArray.push(element.node)
        })
        vendors.forEach(element => {
          vendArray.push(element.node)
        })
        permissionGroups.value = tempArray
        vendorGroups.value = vendArray

        const userGroups = response.data.data.allProfiles.edges[0].node.customuserPtr.groups
          .edges

        const userVendor = response.data.data.allProfiles.edges[0].node.profileVendor.edges
        try {
          const nodesArray = userGroups.map(group => group.node)

          selectedPermissionGroups.value = nodesArray
          if (forbid) {
            if (selectedPermissionGroups.value.name === constants.administrador) {
              router.push({ name: 'misc-not-authorized' })
            }
          }

          // eslint-disable-next-line no-empty
        } catch (error) {

        }
        selectedProfileVendor.value = userVendor.length === 0 ? null : userVendor[0].node

        Object.entries(response.data.data.allProfiles.edges[0].node).forEach(
          ([key, value2]) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
            response.data.data.__type.fields.forEach(value => {
              if (key === value.name) {
                const mvalue = value
                mvalue.value = value2
                tempObject[key] = mvalue
              }
            })
          },
        )
        introspectionData.value = tempObject
      })
      .catch(error => {
        console.log(error)
        if (error.status === 404) {
          userData.value = undefined
        }
      })

    const mutateUser = categories => {
      simpleRules.value.validate().then(success => {
        let pass = true
        try {
          if (userData.value.cpassword === undefined) { userData.value.cpassword = '' }

          if (userData.value.password === undefined) { userData.value.password = '' }

          pass = userData.value.cpassword === userData.value.password
        } catch (error) {
          console.log(error)
        }
        if (success && pass) {
          if (userData.value.cpassword === null) { userData.value.password = null }
          const idsArray = selectedPermissionGroups.value.map(item => item.id)

          store
            .dispatch('admin-user/mutateUser', {
              user: userData.value,
              fields: introspectionData.value,
              groups: idsArray,
              vendor: selectedProfileVendor.value,
              categories,
            })
            .then(response => {
              if (response.data.data.updateCustomuser) {
                userData.value = response.data.data.updateCustomuser.customUser

                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('success'),
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('error'),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.errors[0].message,
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              console.log(error.response.data.errors[0])
              toast({
                component: ToastificationContent,
                props: {
                  title: i18n.t('error'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].message,
                },
              })
            })
        } else {
          let i = 0
          Object.keys(simpleRules.value.$data.errors).map(key => {
            if (simpleRules.value.$data.errors[key][0]) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: simpleRules.value.$data.errors[key][0],
                },
              })
            }
            if (!pass && i === 0) {
              i += 1
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: i18n.t('confirmPassword'),
                },
              })
            }
            return key
          })
        }
      })
    }

    const blankUserData = {
      password: '',
      cpassword: '',
    }

    const userDataBlank = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userDataBlank.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const { getValidationState } = formValidation(resetuserData)

    return {
      userData,
      getValidationState,
      permissionGroups,
      selectedPermissionGroups,
      selectedProfileVendor,
      vendorGroups,
      introspectionData,
      selectOptions,
      mutateUser,
      simpleRules,
    }
  },

  data() {
    return {
      email,
      router,
      required,
      forbid,
      isShop,
      constants,
      length,
      isEmpty,
      disabled: true,
      categories: [],
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    const user = getUserData()
    try {
      if (user.groups.edges[0].node.name !== constants.administrador) {
        forbid = true
      } else {
        forbid = false
      }

      isShop = user.profile.client.isShop
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
  },
  beforeDestroy() {
    if (store.hasModule('admin-user')) store.unregisterModule('admin-user')
  },
  beforeCreate() {
    if (!store.hasModule('admin-user')) store.registerModule('admin-user', userStoreModule)
  },
  methods: {
    categoriesSelected(data) {
      this.categories = data
    },
    includesPermission(string) {
      return this.selectedPermissionGroups.some(item => item.name === string)
    },
    checkPassword(rules) {
      if (rules.min) {
        return (this.$t('pass8Cha'))
      }
      if (rules.password) {
        return (this.$t('passMayus'))
      }
      return ''
    },
    ckeckConfirmPassword(rules) {
      if (rules.confirmed) {
        return (this.$t('confirmPassword'))
      }
      return ''
    },
    changeForm() {
      this.disabled = false
    },
    clck() {
      this.mutateUser(this.categories)
      this.disabled = !this.disabled
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
